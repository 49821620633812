import React, {useMemo} from "react";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {Dropdown, DropdownItem} from "../../components";
import {Beneficiaries, Trustee} from "../EstateFlowchartTypes";

type AddTrustButtonDropdownProps = {
    className: string,
    updateBeneficiaryInformation: Function
}

export const AddDistributionButtonDropdown: React.FC<AddTrustButtonDropdownProps> = ({
                                                                                         className,
                                                                                         updateBeneficiaryInformation
                                                                                     }) => {

    const {id} = useParams<RouteWithId>();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const dropdownItemArray = useMemo(() => {
        return [
            {
                itemText: "Marital Trust",
                value: "Marital Trust",
                onClick: () => {
                    updateBeneficiaryInformation(
                        {
                            firstDeath: {
                                maritalTrust: {
                                    trustName: "",
                                    tags: "",
                                    trustees: [],
                                    successors: [],
                                    beneficiary: [] as Trustee[],
                                    funding: "",
                                    distributions: "",
                                    termination: ""
                                }
                            }
                        } as Beneficiaries)
                },
                disabled: false
            },
        ]
    }, [id])

    return <Dropdown
        className={className}
        alignRight={false}
        buttonIcon="left"
        buttonKind="primary"
        dropdownKind="menu"
        defaultText="Add Distribution"
        iconNameOpen="add"
        iconNameClose="add"
        panelWidth={245}
        rounded
        disabled={isProfileWithProposalsOrArchived}
    >
        {dropdownItemArray.map((item: any, i: number) =>
            <DropdownItem key={i}
                          itemText={item.itemText}
                          value={item.value}
                          onClick={item.onClick}
                          disabled={item.disabled}
            />)}
    </Dropdown>;

};