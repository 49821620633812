import {FamilyMembers, Member, RevocableTrust} from "../EstateFlowchartTypes";
import {MemberGroup} from "src/ClientManagement/models/InvestorGroupType";

export enum formPage {
    basicInfo = "BasicInformation",
    beneficiariesAtFirstDeath = "BeneficiariesAtFirstDeath",
}

/**
 * Checks if the given string is valid.
 * A string is considered valid if it is not undefined and contains
 * at least one non-whitespace character.
 *
 * @param value - The string to be validated.
 * @returns `true` if the string is valid, otherwise `false`.
 */
export const isValid = (value: String | undefined) => {
    return !!(value && value.replace(/\s/g, '').length > 0);
}

/**
 * Validates the form based on the current page and trust data.
 *
 * @param {formPage} page - The current page of the form being validated.
 * @param {RevocableTrust} trust - The trust data to validate against.
 * @returns {{formHasErrors: boolean, errors: any}} An object containing a boolean indicating if the form has errors and an object with the specific errors.
 */
export const validateForm = (page: formPage, trust: RevocableTrust): { formHasErrors: boolean } => {
    const validationSchema = {
        BasicInformation: {
            flowchartName: isValid(trust.flowchartName) ? null : "Flowchart Name is required.",
            legalName: isValid(trust.legalName) ? null : "Legal name is required",
            grantors: trust.grantors && trust.grantors.length > 0 ? null : "Grantors required",
            trustees: trust.trustees && trust.trustees.length > 0 ? null : "Trustees required",
            dateCreated: isValid(trust.trustCreationDate) ? null : "Date Created is required",
            trustJurisdiction: isValid(trust.trustJurisdiction) ? null : "Trust Jurisdiction is required",
        },
        BeneficiariesAtFirstDeath: {
            trustName: isValid(trust.beneficiary?.firstDeath.maritalTrust?.trustName) ? null : "Trust Name is required",
            tags: isValid(trust.beneficiary?.firstDeath.maritalTrust?.tags) ? null : "Tags are required",
            distributions: isValid(trust.beneficiary?.firstDeath.maritalTrust?.distributions) ? null : "Distributions are required",
            trustees: trust.beneficiary?.firstDeath.maritalTrust?.trustees && trust.beneficiary?.firstDeath.maritalTrust?.trustees.length > 0 ? null : "Trustees required",
        }
    }

    const formHasErrors = Object.values(validationSchema[page]).some((validation) => validation !== null)
    return {
        formHasErrors
    }
}

/**
 * Parses the family members from the given data and returns an object containing
 * the primary member's value and label, along with an array of additional family members.
 *
 * @param data - The data containing information about the primary member, partner member, and additional members from the clientManagementApiClient.getMemberGroup endpoint.
 * @returns An object containing the primary member's value and label, and an array of family members.
 */
export const parseFamilyMembers = (data: MemberGroup): FamilyMembers => {
    const value = data.primaryMember.id;
    const label = data.primaryMember.firstName + " " + data.primaryMember.lastName;
    let familyMembers: Member[] = data.additionalMembers.flatMap((member: any) => {
        return [
            {
                value: member.id,
                label: member.firstName + " " + member.lastName,
                selected: false
            }
        ];
    })
    if (data.partnerMember) {
        familyMembers.push({
            value: data.partnerMember.id,
            label: data.partnerMember.firstName + " " + data.partnerMember.lastName,
            selected: false
        });
    }
    // TODO: Should we push primary member to familyMembers here?

    return {value, label, familyMember: familyMembers};
}


export const isUUID = (text: string) => {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(text);
}

/**
 * Checks if a given member ID is not present in the list of family members.
 *
 * @param member_id - The ID of the member to check.
 * @param familyMembers - An object containing the family members.
 * @returns `true` if the member ID is not found in the family members list, otherwise `false`.
 */
export const isCustomMember = (member_id: string, familyMembers: FamilyMembers) => {
    // compare member_id against list of member_id's from the family tree
    const memberIds = familyMembers.familyMember.map(member => member.value);
    return !memberIds.includes(member_id);
}