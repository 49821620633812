import {useEffect, useState} from 'react'
import {RedAsterisk} from 'src/components'
import DropdownMultiselect from './DropdownMultiselect'
import {FamilyMembers, Member, Trustee} from '../EstateFlowchartTypes'
import {MemberGroup} from 'src/ClientManagement/models/InvestorGroupType'
import {parseFamilyMembers} from './validations'

type MemberDropdownMultiselectProps = {
    label: string,
    required: boolean,
    options: MemberGroup,
    selectedOptions: Trustee[] | undefined
    includeNT: boolean,
    onChange: Function,
    onError?: string
}

/**
 * MemberDropdownMultiselect component renders a dropdown multiselect input for selecting members such as trustees or grantors.
 * A Wrapper for dds DropdownMultiselect component
 *
 * @param {Object} props - The properties object.
 * @param {string} props.label - The label for the dropdown.
 * @param {boolean} props.required - Indicates if the dropdown is required.
 * @param {Array} props.options - The list of options available for selection.
 * @param {Array} props.selectedOptions - The list of currently selected options.
 * @param {boolean} props.includeNT - Flag to include "Northern Trust" as an option.
 * @param {Function} props.onChange - Callback function to handle changes in selection.
 * @param {Function} props.onError - Callback function to handle errors.
 *
 * @returns {JSX.Element} The rendered MemberDropdownMultiselect component.
 */
function MemberDropdownMultiselect({label, required, options, selectedOptions, includeNT, onChange, onError}
                                       : MemberDropdownMultiselectProps): JSX.Element {
    const [members, setMembers] = useState<Member[]>([]);
    const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
    const buildMembers = () => {
        const customMembers: Member[] = selectedOptions?.filter((member) => !member.familyMember).map(member => ({
            label: member.customName || "",
            selected: true,
            value: member.memberId || ""
        })) || [];
        const membersSelected: string[] | undefined = selectedOptions?.map(member => member.memberId || "");
        if (includeNT) {
            const hasNorthernTrust = (selectedOptions)?.some(member => member.customName === "Northern Trust");
            if (!hasNorthernTrust) {
                setMembers([{label: "Northern Trust", selected: true, value: ""}]);
            }
        }
        const parsedFamilyMembers: FamilyMembers = parseFamilyMembers(options);
        const primaryMember: Member = {
            selected: false,
            value: parsedFamilyMembers.value,
            label: parsedFamilyMembers.label
        };
        setMembers(prevArray => [...prevArray, ...customMembers, ...parsedFamilyMembers.familyMember, primaryMember]);
        setSelectedMembers(membersSelected ? membersSelected : []);

    }

    useEffect(() => {
        if (Object.keys(options).length > 0) {
            buildMembers()
        }
    }, [options])

    return (
        <div className="layout-data-entry-form__field" data-testid={`${label.toLowerCase()}-dropdown`}>
            <label className={"h5"}>{label}{required ? <RedAsterisk/> : null}</label>
            <DropdownMultiselect
                className={'dds-ms'}
                alignRight={false}
                creatable
                disabled={false}
                dropUp={false}
                dynamicDropAlign={false}
                dynamicDropDirection={false}
                // error={saveHasBeenClicked && (!basicInformation.grantors || basicInformation.grantors.length === 0) ? "Grantor is required." : undefined}
                error={onError ? onError : undefined}
                hideSelectAll
                id="DDMS-creatable"
                label=""
                onChange={(memberOptions: any) => {
                    onChange(memberOptions)
                }}
                open={false}
                options={members}
                panelHeight={members.length * 34 < 280 ? (members.length * 34) + 20 : 280}
                panelWidth="100%"
                placeholder=""
                required={false}
                searchable
                selectAllLabel=""
                selected={selectedMembers}
                selectedDisplayTextAfter=""
                selectedDisplayTextBefore=""
                showTotalSelected={false}
                // showClearAllButton={basicInformation.grantors && basicInformation.grantors.length > 1}
                showFullSelected={true}
                helperText="Add a new name, if it is not in the list."
            />
        </div>
    )
}

export default MemberDropdownMultiselect