import {
    DatePicker,
    Dropdown,
    DropdownItem,
    Input,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../../components";
import React, {ChangeEvent, useEffect, useState} from "react";
import {DISPLAY_DATE_FORMAT} from "../../constants/common";
import moment, {Moment} from "moment";
import {FamilyMembers, Member, RevocableTrust, Trustee, TrusteeType} from "../EstateFlowchartTypes";
import {useAppSelector} from "src/store/hooks";
import {useParams} from "react-router-dom";
import {getEstateFlowcharts} from "../EstateFlowChartSlice";
import {RouteWithIdAndFlowchartId} from "src/routes/types";
import {momentToISO} from "../../utils/dateUtils";
import {isCustomMember, isValid, parseFamilyMembers} from "./validations";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import SuccessorTable from "./SuccessorTable";
import {ReferenceDataType} from "../../models/referenceData/ReferenceDataType";
import {referenceDataClient} from "../../core/ReferenceDataClient";
import {MemberGroup} from "src/ClientManagement/models/InvestorGroupType";
import LoadingIndicator from "src/pages/LoadingIndicator";
import MemberDropdownMultiselect from "./MemberDropdownMultiselect";

type BasicInformationContentProps = {
    saveHasBeenClicked: boolean,
    updatedRevocableTrust: Function,
    id: string
}

const initialMemberItem = {
    label: "", selected: false, value: ""
}

/**
 * Component for rendering the basic information content of an estate flowchart.
 *
 * @component
 * @param {BasicInformationContentProps} props - The properties for the component.
 * @param {boolean} props.saveHasBeenClicked - Indicates if the save button has been clicked.
 * @param {Function} props.updatedRevocableTrust - Function to update the revocable trust.
 * @param {string} props.id - The ID of the estate flowchart.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <BasicInformationContent
 *   saveHasBeenClicked={true}
 *   updatedRevocableTrust={handleUpdateRevocableTrust}
 *   id="12345"
 * />
 *
 * @remarks
 * This component handles the display and update of basic information related to a revocable trust.
 * It includes fields for flowchart name, legal name, nickname, date created, record of amendments,
 * grantors, trustees, funding, investment advisor, trust protector, and trust jurisdiction.
 *
 * The component uses various hooks such as `useState`, `useEffect`, and custom hooks like `useAppSelector`.
 * It also interacts with APIs to fetch family members and reference data.
 *
 * The component includes validation for required fields and dynamically updates the state based on user input.
 */
const BasicInformationContent: React.FC<BasicInformationContentProps> = ({
    saveHasBeenClicked,
    updatedRevocableTrust,
    id
}: BasicInformationContentProps): JSX.Element => {

    const [basicInformation, updateBasicInformation] = useState<RevocableTrust | undefined>(undefined);
    const estateFlowchartsInState = useAppSelector(getEstateFlowcharts);
    const {flowchartId} = useParams<RouteWithIdAndFlowchartId>();
    const [grantors, setGrantors] = useState([] as Member[])
    const [familyMembers, setFamilyMembers] = useState({} as MemberGroup);
    const [familyMembersResponse, setFamilyMembersResponse] = useState({} as FamilyMembers);
    const dropdownHeight: number = grantors.length * 34 < 280 ? (grantors.length * 34) + 20 : 280
    const [states, updateStates] = useState<any[] | undefined>(undefined);
    const getFamilyMembers = async (): Promise<MemberGroup> => {
        return clientManagementApiClient.getMemberGroup(id);
    }

    const errorMessage = (field: string, data: string) => {
        if(saveHasBeenClicked && !isValid(data)){
            switch (field) {
                case "flowchartName":
                    return "Flowchart Name is required.";
                case "legalName":
                    return "Legal Name of Trust or Entity is required.";
                case "trustCreationDate":
                    return "Date Created is required.";
                case "grantors":
                    return "Grantor is required.";
                case "trustees":
                    return "Trustee is required.";
                case "trustJurisdiction":
                    return "Trust Jurisdiction is required.";
                default:
                    return "";
            }
        }else{
            return undefined;
        }
    }

    const buildInitialForm = () => {
        const initializeTrust = (trust: RevocableTrust | undefined) => {
            if (trust) {
                updateBasicInformation(trust);
            }
        };
        if (estateFlowchartsInState.length > 0) {
            const trust = estateFlowchartsInState.find(flowchart => flowchart.flowchartId === flowchartId)?.revocableTrust;
            initializeTrust(trust);
        } else if (flowchartId === 'new') {
            initializeTrust({} as RevocableTrust)
        }
    };

    const onChangeDropdown = (selectedOptions: any, type: TrusteeType) => {
        if (basicInformation) {
            const members: Trustee[] = [];
            for (const element of selectedOptions) {
                const member: Trustee = {
                    trustId: "",
                    memberType: type,
                    familyMember: true,
                    customName: "",
                    memberOrder: undefined
                };
                member.memberType = type;
                member.trustId = basicInformation.revTrustId;
                if (element.hasOwnProperty("icon")) { // only for new custom
                    member.customName = element.label;
                    member.familyMember = false;
                } else { // family members and previous custom
                    member.familyMember = !isCustomMember(element.value, familyMembersResponse);
                    member.memberId = element.value;
                    if (isCustomMember(element.value, familyMembersResponse)) {
                        member.customName = element.label;
                    } else {
                        member.customName = "";
                    }
                }
                members.push(member);
            }
            switch (type) {
                case TrusteeType.Grantor:
                    updateBasicInformation({...basicInformation, grantors: members});
                    break;
                case TrusteeType.Trustee:
                    updateBasicInformation({...basicInformation, trustees: members});
                    break;
                default:
            }
        }

    }

    useEffect(() => {
        updatedRevocableTrust(basicInformation)
    }, [basicInformation]);

    useEffect(() => {
        let isMounted = true;
        getFamilyMembers().then((res) => {
            const parsedFamilyMembersResponse = parseFamilyMembers(res);
            //TODO: Should we just do this in the parse function?
            let item = initialMemberItem;
            item.label = parsedFamilyMembersResponse.label;
            item.value = parsedFamilyMembersResponse.value;
            parsedFamilyMembersResponse.familyMember.push(item);
            if (isMounted) setFamilyMembersResponse(parsedFamilyMembersResponse);
            if (isMounted) setGrantors(prevArray => [...prevArray, ...parsedFamilyMembersResponse.familyMember]);
            if (isMounted) setFamilyMembers(res)
        })

        referenceDataClient.getReferenceData()
            .then((data: ReferenceDataType) => {
                const statesOfResidency: any = data.statesOfResidency
                let listOfStates: { itemText: string, value: string }[] = [];
                for (const key in statesOfResidency) {
                    let item: any = {};
                    item.value = key;
                    item.itemText = statesOfResidency[key];
                    listOfStates.push(item);
                }
                listOfStates.sort((a, b) => a.itemText.localeCompare(b.itemText))
                if (isMounted) updateStates(listOfStates);
            }).catch(() => {
            if (isMounted) updateStates([]);
        })
        return () => {
            isMounted = false;
        }
    }, []);


    useEffect(() => {
        buildInitialForm();
    }, [estateFlowchartsInState]);

    if (!states || !estateFlowchartsInState || !basicInformation) {
        return <LoadingIndicator/>;
    }

    return (
        <>
            <section aria-label="Basic Info" className="basic-information-form">
                <h2 className={"fontweight-500 marginbottom-xl"}>Basic Information</h2>
                <UnderlinedHeader
                    leftAlignedContent={<div className="required-header" data-testid={'basicInfo-container'}>
                        <h4>Basic
                            Details</h4><RequiredFieldsSubheader/></div>}/>

                <div className="textalign-right form-main">
                    <div className="layout-data-entry-form__field">
                        <label className={"h5"} data-testid={'flowchart-name'}>Flowchart Name<RedAsterisk/></label>
                        <Input
                            aria-label="Flowchart Name"
                            size="medium"
                            value={basicInformation.flowchartName || ""}
                            error={errorMessage("flowchartName", basicInformation.flowchartName)}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                                ...basicInformation, flowchartName: e.target.value
                            })}
                        />
                    </div>
                    <div className="layout-data-entry-form__field">
                        <label className={"h5"} data-testid={'legal-name'}>Legal Name of Trust or
                            Entity<RedAsterisk/></label>
                        <Input
                            aria-label="Legal Name"
                            size="medium"
                            value={basicInformation.legalName || ''}
                            error={errorMessage("legalName", basicInformation.legalName)}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                                ...basicInformation, legalName: e.target.value
                            })}

                        />
                    </div>
                    <div className="layout-data-entry-form__field">
                        <label className={"h5"} data-testid={'nick-name'}>Nickname</label>
                        <Input
                            aria-label="Nickname"
                            size="medium"
                            value={basicInformation.nickName || ''}
                            disabled={false}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                                ...basicInformation, nickName: e.target.value
                            })}
                        />
                    </div>
                    <div className="layout-data-entry-form__field">
                        <label className={"h5"} data-testid={'date-created'}>Date Created<RedAsterisk/></label>
                        <DatePicker
                            className="createdDateField"
                            id="createdDateInput"
                            aria-label="Date Created"
                            displayFormat={DISPLAY_DATE_FORMAT}
                            hideKeyboardShortcutsPanel
                            isOutsideRange={(date: Moment) => date.isAfter(moment().endOf('day'))}
                            date={basicInformation.trustCreationDate ? moment(basicInformation.trustCreationDate) : undefined}
                            error={errorMessage("trustCreationDate", basicInformation.trustCreationDate)}
                            onDateChange={
                                (date: Moment) => {
                                    updateBasicInformation({
                                        ...basicInformation,
                                        trustCreationDate: date !== null ? momentToISO(date) : ""
                                    })
                                }
                            }
                        />
                    </div>
                    <div className="layout-data-entry-form__field">
                        <div className={"textarea-label"}><label className={"h5"}
                                                                 data-testid={'record-of-amendments'}>Record
                            of Amendments</label>
                            <span
                                className='textarea-limit-count'>{basicInformation?.recordOfAmendment ? basicInformation?.recordOfAmendment?.length : 0}/500</span>
                        </div>
                        <textarea
                            name="Record of Amendments"
                            className="textarea-500-limit input-skin"
                            data-testid='record-of-amendments-field'
                            autoFocus={false}
                            rows={5}
                            cols={50}
                            maxLength={500}
                            defaultValue={basicInformation.recordOfAmendment ? `${basicInformation.recordOfAmendment}` : ''}
                            onChange={(e) => {
                                updateBasicInformation({
                                    ...basicInformation, recordOfAmendment: e.target.value
                                })
                            }
                            }
                        />
                    </div>
                </div>
            </section>
            <section aria-label="Trust Details" className="trust-details-form">

                <UnderlinedHeader
                    leftAlignedContent={<div className="required-header" data-testid={'trustDetails-container'}>
                        <h4>Trust Details</h4> <RequiredFieldsSubheader/></div>}/>
                <MemberDropdownMultiselect
                    label="Grantors"
                    required={true}
                    options={familyMembers}
                    selectedOptions={basicInformation.grantors ? basicInformation.grantors : []}
                    includeNT={true}
                    onChange={(options: any) => onChangeDropdown(options, TrusteeType.Grantor)}
                    onError={saveHasBeenClicked && (!basicInformation.grantors || basicInformation.grantors.length === 0) ? "Grantor is required." : undefined}
                />

                <MemberDropdownMultiselect
                    label="Trustees"
                    required={true}
                    options={familyMembers}
                    selectedOptions={basicInformation.trustees ? basicInformation.trustees : []}
                    includeNT={true}
                    onChange={(options: any) => onChangeDropdown(options, TrusteeType.Trustee)}
                    onError={saveHasBeenClicked && (!basicInformation.trustees || basicInformation.trustees.length === 0) ? "Trustee is required." : undefined}
                />

                {
                    familyMembersResponse.familyMember && familyMembersResponse.familyMember.length > 0 &&
                    <SuccessorTable formData={basicInformation}
                                    successorType={TrusteeType.Successor}
                                    dropdownHeight={dropdownHeight}
                                    familyMembersResponse={familyMembersResponse}
                                    updateData={updateBasicInformation}/>
                }
                <div className="layout-data-entry-form__field basic-information__funding">
                    <div className={"textarea-label"}><label className={"h5"}
                                                             data-testid={'funding'}>Funding</label>
                        <span
                            className='textarea-limit-count'>{basicInformation?.funding ? basicInformation?.funding?.length : 0}/500</span>
                    </div>
                    <textarea
                        name="Funding"
                        className="textarea-500-limit input-skin"
                        data-testid='funding-field'
                        autoFocus={false}
                        rows={5}
                        cols={50}
                        maxLength={500}
                        defaultValue={basicInformation.funding ? `${basicInformation.funding}` : ''}
                        onChange={(e) => {
                            updateBasicInformation({
                                ...basicInformation, funding: e.target.value
                            })
                        }
                        }
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label className={"h5"} data-testid={'investment-advisor'}>Investment Advisor</label>
                    <Input
                        aria-label="Investment Advisor"
                        size="medium"
                        value={basicInformation.investmentAdvisor || ''}
                        disabled={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                            ...basicInformation, investmentAdvisor: e.target.value
                        })}
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label className={"h5"} data-testid={'nick-name'}>Trust Protector</label>
                    <Input
                        aria-label="Trust Protector"
                        size="medium"
                        value={basicInformation.trustProtector || ''}
                        disabled={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                            ...basicInformation, trustProtector: e.target.value
                        })}
                    />
                </div>
                <div className="layout-data-entry-form__field basic-information__trust-jurisdiction"
                     data-testid={'trust-jurisdiction-dropdown'}>
                    <label className={"h5"} data-testid={'trust-jurisdiction'}>Trust Jurisdiction<RedAsterisk /></label>
                    <Dropdown
                        className="trust-jurisdiction-field"
                        name="trustJurisdiction"
                        id="trustJurisdiction"
                        aria-label="trustJurisdiction"
                        aria-labelledby="trustJurisdiction-label"
                        alignRight={false}
                        buttonIcon="right"
                        buttonKind="secondary"
                        defaultPageSize={15}
                        dropdownKind="select"
                        dynamicDropAlign={true}
                        dynamicDropDirection={true}
                        iconNameClose="arrow_drop_up"
                        iconNameOpen="arrow_drop_down"
                        nativeOnMobile={false}
                        panelHeight={232}
                        panelWidth="100%"
                        searchable
                        defaultText="Select..."
                        onChange={(e: any) => updateBasicInformation({
                            ...basicInformation, trustJurisdiction: e.value
                        })}
                        size="medium"
                        virtualScroll={false}
                        error={errorMessage("trustJurisdiction", basicInformation.trustJurisdiction)}
                        value={basicInformation.trustJurisdiction || ''}
                    >
                        {states?.map(state => (
                            <DropdownItem key={state.value} itemText={state.itemText} value={state.value}/>
                        ))}
                    </Dropdown>
                </div>
            </section>
        </>
    );

}

export default BasicInformationContent;