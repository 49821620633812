export type RevocableTrust = {
    flowchartName: string,
    legalName: string,
    nickName: string | null,
    trustCreationDate: string,
    recordOfAmendment: string | null
    revTrustId: string,
    grantors: Trustee[],
    trustees: Trustee[],
    successors: Trustee[],
    funding: string | null,
    investmentAdvisor: string | null,
    trustProtector: string | null,
    trustJurisdiction: string,
    beneficiary?: Beneficiaries | null,
}

export enum TrusteeType {
    Grantor = "GRANTOR",
    Trustee = "TRUSTEE",
    Successor = "SUCCESSOR_TRUSTEE",
    Beneficiary = "BENEFICIARY",
    BeneficiaryTrustee = "BENEFICIARY_TRUSTEE",
    BeneficiarySuccessor = "BENEFICIARY_SUCCESSOR",

}

export type Trustee = {
    trustId: string | undefined,
    memberId?: string | null,
    memberType: TrusteeType,
    memberOrder?: number,
    customName?: string,
    familyMember: boolean
}

export type Member = {
    value: string,
    label: string,
    selected: boolean
}

export type FamilyMembers = {
    value: string,
    label: string,
    familyMember: Member[]
}

export type Beneficiaries = {
    firstDeath: {
        maritalTrust?: {
            trustName: string,
            tags: string,
            trustees: Trustee[],
            successors: Trustee[],
            beneficiary: Trustee[],
            funding: string,
            distributions: string,
            termination: string
        }
    }
}

/**
 *There can be more than 1 flowchart per Profile; Each flowchart will have one rev/irrev x
 */
export type EstateFlowchartState = {
    flowchartId: string | undefined
    revocableTrust: RevocableTrust
}

export type TrustValidation = {
    revocableTrust: null | RevocableTrustValidation,
}

export type RevocableTrustValidation = null | {
    flowchartName: string | null,
    legalName: string | null
}